import React from "react";
import "./ReportMachine.css";
import Header from "../Common/Header/Header";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import jsPDF from "jspdf";
import { useNavigate, useLocation } from "react-router-dom";

const ReportMachine = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const date = location.state.date;
  const roomName = location.state.roomName;
  let array = location?.state?.filterDate;
  let ar = array[0]?.meterReadings;
  let arr = [].concat(ar).reverse();

  // Get Total Values
  let arrLen = arr.length
  let totalT = arr[0]?.total - arr[arr.length - 1]?.total;
  let totalAvg = totalT/arrLen
  let linetemp = 0
  let addlinetemp = arr?.map((data)=>{
    return parseFloat(data?.linetemp)
  })
  for (var i in addlinetemp){
    linetemp +=addlinetemp[i]
  }
  let linetempavg = linetemp/arrLen


  const reportformat =
    "Q-Senima\t" + roomName + "\t" + date.startDate + "\t\t" + date.endDate;
  const pdfbtn = () => {
    var doc = new jsPDF("l", "pt");
    var content = document.getElementById("reportid");
    doc.html(content, {
      callback: function (doc) {
        const pageCount = doc.internal.getNumberOfPages();
        doc.setFontSize(12);
        for (var i = 1; i <= pageCount; i++) {
          doc.setPage(i);
          // doc.rect(20, 20, doc.internal.pageSize.width - 40, doc.internal.pageSize.height - 40, 'S');
          doc.text(
            "Page" + String(i) + " of " + String(pageCount),
            842 - 30,
            595 - 30,
            null,
            null,
            "right"
          );
        }
        doc.save(reportformat);
      },
    });
  };
  const close = () => {
    navigate(-1);
  };

  return (
    <>
      <div className="reportmain">
        <div className="report" id="reportid">
          <Header roomName={roomName} />
          <div className="date">
            {date.startDate} ~ {date.endDate}
          </div>
          <table className="tablemain" id="tablee">
            <thead className="tablehead">
              <tr className="tablerow">
                <th className="tableh">Time | Date</th>
                {arr[arr.length - 1]?.flow && (
                  <th className="tableh">Flow m3/hr</th>
                )}
                {arr[arr.length - 1]?.total && (
                  <th className="tableh">Total Flow m3</th>
                )}
                {arr[arr.length - 1]?.linetemp && (
                  <th className="tableh">Line Temp °C</th>
                )}
              </tr>
            </thead>
            <tbody className="tablebody">
              {arr.map((ddd, i) => {
                return (
                  <tr className="tablerow" key={i}>
                    <td className="tabled datelink"   onClick={() => {
                        let filterHour = array?.map((meter)=>{
                          return{
                            ...meter,
                            HourReadings : meter?.HourReadings.filter((val)=>{
                              let dateSearch = val?.date
                              if(dateSearch===ddd?.date){
                                return true;
                              }
                              else{
                                return false;
                              }

                            })
                          }
                        })
                        navigate(`/dashboard/reportHour`,{
                          state : { filterDateHour : filterHour , date : date , roomName : roomName }
                        });
                      }}
                      >
                      {ddd?.time} | {ddd?.date}
                    </td>
                    {ddd?.flow && (
                      <td className="tabled">
                        {parseInt(ddd?.totalDayReduction).toLocaleString()}
                      </td>
                    )}
                    {arr[arr.length - 1]?.total && (
                      <td className="tabled">
                        {parseInt(ddd?.total).toLocaleString()}{" "}
                      </td>
                    )}
                    {arr[arr.length - 1]?.linetemp && (
                      <td className="tabled">
                        {parseInt(ddd?.linetemp).toLocaleString()}{" "}
                      </td>
                    )}
                  </tr>
                );
              })}
              <tr className="tablerow">
                <th className="tableh tablehead">Avg | Total</th>
                {arr[arr.length - 1]?.total && (
                  <td className="tabled tablehead">
                    {parseInt(totalAvg).toLocaleString()}
                  </td>
                )}
                {arr[arr.length - 1]?.total && (
                  <td className="tabled tablehead">
                    {parseInt(totalT).toLocaleString()}
                  </td>
                )}
                {arr[arr.length - 1]?.linetemp && (
                  <td className="tabled tablehead">
                    {parseInt(linetempavg).toLocaleString()}
                  </td>
                )}
                
              </tr>
            </tbody>
          </table>
        </div>
        <div className="btnss">
          <ReactHTMLTableToExcel
            id="test-table-xls-button"
            className="excelbtn"
            table="tablee"
            filename={reportformat}
            sheet="tablexls"
            buttonText="Excel"
          />
          <button className="pdfbtn" onClick={pdfbtn}>
            Pdf
          </button>
          <button className="pdfbtn" onClick={close}>
            Close
          </button>
        </div>
      </div>
    </>
  );
};
export default ReportMachine;
