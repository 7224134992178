import React from 'react'
// import './Header.css'
import './HeaderLogin.css'
import cue from '../../../Asserts/Logo/cue.png'


export default function Header(props) {

    return (
        <>
            <div className="machineHeaderLogin">
                <img className='machineimagelogoLogin' src={cue} alt="Uploading" />
                <div className="machineheadingLogin"><strong className='boldmachineheadingLogin'> MRS9000</strong> Monitoring & Reporting System</div>
                <div className="datetimeupdatedLogin">{props.timedate}</div>
            </div>
        </>
    )
}
